.booking-card {
    max-width: 1272px;
    min-height: 928px;
    height: auto;
    border-radius: 12px;
    background: rgba(226, 220, 196, 1);
    position: relative;
    margin: 0 auto;
    margin-top: 350px;
    padding-top: 298px;
    padding-bottom: 40px;
}

.booking-card .main-img {
   border-radius: 40px;

    height: 500px;
    top: -250px;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    overflow: hidden;
}

.booking-card h5 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60.24px;
    text-align: center;
    color: rgba(11, 22, 35, 1);
    margin: 0px auto 0;
}

.booking-card table {
    margin: 31px auto 0;
    margin-bottom: 30px;
}
.paddingTable{
    padding: 30px;
}
.booking-card table thead tr th {
    background: rgba(214, 186, 144, 0.83);
    padding: 20px 30px;
    border-radius: 8px;
    margin: 0 10px;
    font-family: Mulish;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.14px;
    text-align: center;
    color: rgba(0, 0, 0, 1);

}

.booking-card table tbody tr td:first-child {
    background: rgba(214, 186, 144, 0.83);
    padding: 20px 30px;
    border-radius: 8px;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.14px;
    text-align: center;
    color: rgba(0, 0, 0, 1);

}

.booking-card table tbody tr td {
    background: rgba(255, 255, 255, 1);
    padding: 20px 30px;
    border-radius: 8px;
    font-size: 32px;
    font-weight: 700;
    line-height: 40.16px;
    text-align: center;
    color: rgba(0, 0, 0, 1);


}

.booking-card .book-cta {
    width: 436px;
    height: 82px;
    border-radius: 8px;
    background: rgba(214, 186, 144, 1);
    border: 4px solid rgba(117, 76, 41, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 40px;
    font-weight: 700;
    line-height: 50.2px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin: 48px auto 0;

}

.booking-card .book-cta:hover {
    opacity: .7;
}
h6{
    text-align: center;
    font-size: 35px;
    margin-bottom: 10px;
    font-family: 'Brush Script MT', cursive;


}
.subtitle{
 
    font-size: 30px !important;
    font-weight: lighter !important;
    margin-top: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

@media screen and (max-width: 992px) {
    .paddingTable{
        padding: 0px;
    }
    .booking-card {
        max-width: 767px;
        min-height: 802px;
        padding-top: 190px;
        padding-bottom: 40px;
        margin-top: 250px;

    }

    .booking-card table thead tr th {
        padding: 15px 20px;
        font-size: 24px;
    }

    .booking-card table tbody tr td:first-child {
        padding: 15px 20px;
        font-size: 24px;
    }

    .booking-card table tbody tr td {
        padding: 15px 20px;
        font-size: 24px;
    }

    .booking-card .main-img {
        top: -160px;
        width: 80%;
    }


}
@media screen and (max-width: 767px) {
    .booking-card {
        padding-top: 140px;
        padding-bottom: 40px;
        margin-top: 250px;
    }

    .booking-card table thead tr th {
        padding: 10px 15px;
        font-size: 20px;
    }

    .booking-card table tbody tr td:first-child {
        padding: 10px 15px;
        font-size: 20px;
    }

    .booking-card table tbody tr td {
        padding: 10px 15px;
        font-size: 20px;
    }

    .booking-card .main-img {
        top: -160px;
        width: 100%;
    }


}
@media screen and (max-width: 567px) {
    #packages-section {
        padding: 60px 0 60px;
    }
    #packages-section .title {
        font-size: 48px;
        line-height: 40.32px;
    }

    .booking-card table thead tr th {
        padding: 10px;
        font-size: 15px;
        line-height: 20px;
    }

    .booking-card table tbody tr td:first-child {
        padding: 10px;
        font-size: 15px;
        line-height: 20px;
    }

    .booking-card table tbody tr td {
        padding: 10px;
        font-size: 15px;
        line-height: 20px;
    }
    .booking-card {
        margin-top: 60px;
        padding-top: 30px;
        min-height: 620px;
    }
    .booking-card h5 {
        font-size: 40px;
        line-height: 32.24px;
    }

    .booking-card .book-cta {
        width: 100%;
        max-width: 336px;
        font-size: 30px;
        line-height: 30px;
    }
    .booking-card .main-img {
        width: 90%;
        height: 100%;
        position: static;
        transform: translate(0);
        margin: 0 auto 40px;
    }





}
@media screen and (max-width: 410px) {
    .booking-card table thead tr th {
        padding: 4px;
        font-size: 12px;
        line-height: 18px;
    }

    .booking-card table tbody tr td:first-child {
        padding: 4px;
        font-size: 12px;
        line-height: 18px;
    }

    .booking-card table tbody tr td {
        padding: 4px;
        font-size: 12px;
        line-height: 18px;
    }
}