header {
    background: rgba(226, 220, 196, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
}

.nav .nav__brand {
    text-transform: uppercase;
}

.nav .call-btn {
    width: 144px;
    height: 62px;
    border-radius: 8px;
    border: 2px solid rgba(117, 76, 41, 1);
    font-size: 24px;
    font-weight: 700;
    line-height: 30.12px;
    text-align: center;
    color: rgba(11, 22, 35, 1);
    margin-left: 150px;
}

.nav .call-btn:hover {
    opacity: .7;
}

.nav .nav__item a {
    font-size: 24px;
    font-weight: 600;
    line-height: 30.12px;
    text-align: left;
    color: rgba(11, 22, 35, 1);

}

.nav__menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 70px;
    padding: 0;
}

.nav__toggler {
    display: none;
}

.nav__toggler div {
    width: 1.8rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: rgba(11, 22, 35, 1);
    transition: 0.3s ease-in;
}

.nav__item.active .nav__link {
    padding-bottom: 30px;
    border-bottom: 6px solid rgba(117, 76, 41, 1);
}

@media screen and (max-width: 1200px) {
    .nav__menu {
        gap: 40px;
    }  
    .nav .call-btn {
        margin-left: 50px;
    }

}


@media screen and (max-width: 992px) {
    .nav__item.active .nav__link {
        padding-bottom: 10px;
    }
    .nav__toggler {
        display: block;
        cursor: pointer;
    }

    .nav__menu {
        position: absolute;
        top: calc(var(--header-height) - 18px);
        right: 0;
        height: auto;
        padding: 20px 0;
        width: 100%;
        background: #fff;
        flex-direction: column;
        transform: translateX(-100%);
        transition: 0.4s ease-in;
    }

    /* Active Class */
    .nav .nav__active .call-btn {
        margin-left: 0px;
        opacity: 1;
        transform: scale(1);
    }

    header li {
        opacity: 0;
        transform: scale(0);
        transition: .8s ease-out;
    }

    .nav .call-btn {
        opacity: 0;
        transform: scale(0);
        transition: .8s ease-out;
        width: 100%;
        max-width: 300px;
    }

    .nav .call-btn:hover {
        border: 1px solid rgba(11, 22, 35, 1);
        color: rgba(11, 22, 35, 1);
        background: #fff;
    }

    .nav__active {
        transform: translateX(0%);
    }

    .nav__active li {
        opacity: 1;
        transform: scale(1)
    }

    /* Toggle Icon Animation */

    .toggle .line1 {
        transform: rotate(-45deg) translate(-9px, 2px);
    }

    .toggle .line2 {
        opacity: 0;
    }

    .toggle .line3 {
        transform: rotate(45deg) translate(-11px, -5px);
    }

}