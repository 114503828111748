#packages-section {
    background: rgba(255, 255, 255, 1);
    margin-top: var(--header-height);
    padding: 80px 0 60px;
    height: auto;
    width: 100%;
}

#packages-section .title {
    font-size: 54px;
    font-weight: 700;
    line-height: 80.32px;
    text-align: center;
    color: rgba(11, 22, 35, 1);
    margin: 0 auto;
}

#packages-section .booking-box {
    margin-top: 80px;
}

.Loading{
    width: 100%;
    text-align: center;
    font-size: medium;
}