#blog-section {
    margin-top: var(--header-height);
    background-color: #fff;
    padding: 84px 0 40px;
    height: auto;
    width: 100%;
}

#blog-section h1 {

    font-size: 40px;
    font-weight: 700;
    line-height: 50.2px;
    text-align: left;
    color: rgba(11, 22, 35, 1);
    margin: 44px 0 0 0;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

#blog-section .box {
    margin-top: 17px;
    gap: 200px;
    
}

#blog-section .box h5 {
    font-family: Mulish;
    font-size: 24px;
    font-weight: 400;
    line-height: 30.12px;
    text-align: left;
    color: rgba(11, 22, 35, 1);
    margin: 0px 0 0 0;

}

#blog-section .description:first-of-type {
    margin: 55px 0 0 0;
}

#blog-section .description {
    font-size: 28px;
    font-weight: 500;
    line-height: 35.14px;
    text-align: left;
    color: #000;
    margin: 20px 0 0 0;
}

#blog-recommended-section {
    margin: 10px 0 120px 0;
}

#blog-recommended-section h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50.2px;
    text-align: left;
    color: rgba(11, 22, 35, 1);
    margin: 0;

}

#blog-recommended-section .blog-card-row {
    margin-top: 32px;
}
.BlogImage{
    height: 500px;
    width: auto;
    max-width: 100%;
    border-radius: 30px;
}
.imagecontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerblog{
    border: 2px solid black;
    border-radius: 30px;
    background: rgba(214, 186, 144, 0.164);
    padding: 20px;
   

}
.Loading{
    width: 100%;
    text-align: center;
    font-size: medium;
    margin-top: 15vh;
}
@media screen and (max-width: 1400px) {
    #blog-recommended-section .blog-card-row {
        gap: 12px;
    }
}
@media screen and (max-width: 1200px) {
    #blog-recommended-section .blog-card-row {
        gap: 60px;
    }
}
@media screen and (max-width: 992px) {
    #blog-recommended-section .blog-card-row {
        justify-content: center;
    }
    #blog-section .box {
        gap: 20px;
    }
    
}
@media screen and (max-width: 567px) {
    .BlogImage{
        height: 100%;
        width: 100%;
    }
    #blog-section h1 {
        font-size: 30px;
        line-height: 38.2px;
    }
    #blog-section .box h5 {
        font-size: 20px;
        line-height: 30.12px;
    }
    #blog-section .description {
        font-size: 22px;
        line-height: 32.14px;
    }

    #blog-recommended-section .description {
        font-size: 30px;
        line-height: 45.2px;
    }
    .containerblog{
        border: 2px solid black;
        border-radius: 30px;
        background: rgba(214, 186, 144, 0.164);
        padding: 15px;
        
       
    
    }










}
