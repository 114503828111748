#about-section {
    background: #E2DCC4;
    width: 100%;
    height: auto;
    padding: 120px 0 120px;
}

#about-section h4 {
    font-size: 64px;
    font-weight: 700;
    line-height: 80.32px;
    text-align: center;
    color: #0B1623;
    margin: 0 auto;
}

#about-section .main-img {
    margin: 120px auto 0;
}

#about-section .box {
    max-width: 1400px;
    margin: 0 auto;
}

#about-section .box h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 40.16px;
    text-align: left;
    color: #000000;
    margin: 120px 0 0;
    max-width: 804px;
}

#about-section .box .bottom-text {
    text-align: right;
    margin: 84px 0 0 auto;

}

#about-section a {
    width: 435px;
    height: 84px;
    border-radius: 8px;
    background: #D6BA90;
    border: 4px solid #FFFFFF;
    font-size: 40px;
    font-weight: 800;
    line-height: 50.2px;
    text-align: left;
    color: #FFFFFF;
    margin: 120px auto 0;
}

#about-section a:hover {
    opacity: .7;
}
.MoreText{
    margin-top: 100px;
    width: 100% !important;
    padding: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
   
}
.HeadingMoreText{
    
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 567px) {
    #about-section {
        padding: 80px 0 80px;
    }

    #about-section .box h2 {
        font-size: 28px;
        line-height: 38px;
        margin: 60px 0 0;
    }

    #about-section a {
        width: 435px;
        font-size: 30px;
        color: #FFFFFF;
        margin: 60px auto 0;
        max-width: 100%;
    }
}