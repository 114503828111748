footer {
    background-color: #E2DCC4;
    padding: 105px 0 0px;
    width: 100%;
    height: auto;
    border-bottom: 12px solid #C6A068;
}

footer .logo-box {}

footer .logo-box .social-links {
    margin-top: 40px;
    margin-bottom: 30px;
}

footer .logo-box .social-links a:hover {
    opacity: .7;
}

footer .links-box {
    margin-bottom: 30px;
}

footer .links-box h5 {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.14px;
    text-align: left;
    color: #231F20;
    margin: 0;
}

footer .links-box ul {
    padding: 0;
    margin: 0;
}

footer .links-box ul li:first-of-type {
    margin-top: 23px;
}

footer .links-box ul li {
    margin-top: 20px;
}

footer .links-box ul li a {
    font-size: 20px;
    font-weight: 600;
    line-height: 30.12px;
    text-align: left;
    color: #0B1623;
    margin: 0;
}

footer .links-box ul li a:hover {
    opacity: .7;
}

footer .contact-box {}

footer .contact-box h5 {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.14px;
    text-align: left;
    color: #231F20;
    margin: 0;
}

footer .contact-box ul {
    padding: 0;
    margin: 0;
}

footer .contact-box ul li:first-of-type {
    margin-top: 23px;
}

footer .contact-box ul li {
    margin-top: 20px;
}

footer .contact-box ul li a {
    font-size: 20px;
    font-weight: 600;
    line-height: 30.12px;
    text-align: left;
    color: #0B1623;
    margin: 0;
}

footer .contact-box ul li a:hover {
    opacity: .7;
}

footer .learn-more-box {}

footer .learn-more-box h5 {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.14px;
    text-align: left;
    color: #231F20;
    margin: 0;
    margin-top: 40px;
}

footer .learn-more-box p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #231F20;
    margin: 8px 0 0 0;
    max-width: 331px;
}

footer .learn-more-box .box {
    margin-top: 16px;
}

footer .learn-more-box .box a {
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    color: #231F20;
    margin: 0;

}

footer .learn-more-box .box a:hover {
    opacity: .7;
}

footer .learn-more-box .input-box {
    margin-top: 16px;
}

footer .learn-more-box .input-box #email {
    width: 243px;
    height: 53px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 0 0 0 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: rgba(35, 31, 32, 1);
}

footer .learn-more-box .input-box .image-box {
    width: 53px;
    height: 53px;
    border-radius: 0px 4px 4px 0px;
    background: rgba(198, 160, 104, 1);
    display: flex;
    align-items: center;
    justify-content: center;

}

footer .upper-box {
    width: 664px;
    height: 119px;
    border-radius: 24px;
    background: rgba(198, 160, 104, 1);

    padding: 0px 50px 0px;
    top: -70px;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
}

footer .upper-box h5 {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.14px;
    text-align: left;
    color: rgba(35, 31, 32, 1);
    margin: 0;
}

footer .upper-box a {
    width: 206px;
    height: 72px;
    border-radius: 8px;
    border: 4px solid rgba(255, 255, 255, 1);
    font-size: 32px;
    font-weight: 700;
    line-height: 40.16px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin: 0;
}
footer .upper-box a:hover{
    opacity: .7;
}
footer .lower-footer{
    border-top: 1px solid #C6A068;
    padding: 10px 0 10px;
    margin-top: 40px;
}
footer .lower-footer h4{
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #000;
    margin: 0;
}
footer .lower-footer h4 a{
    text-decoration: underline;
    color: #000;
    font-weight: 700;
}
footer .lower-footer h4 a:hover{
    opacity: .7;
}
@media screen and (max-width: 1400px) {
    footer .g-90{
        gap: 50px;
    }
    
}
@media screen and (max-width: 992px) {
    footer .upper-box {
        width: 664px;
        height: 119px;
        max-width: 100%;
    }
}
@media screen and (max-width: 567px) {
    footer .upper-box {
        position: static;
        transform: translate(0px);
        margin: 0 auto;
        width: 100%;
        max-width: 380px;
        height: auto;
        padding: 20px;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    footer .upper-box h5 {
        font-size: 20px;
    }
    footer .upper-box a {
        width: 180px;
        height: 50px;
        font-size: 24px;
        max-width: 100%;
    }
    footer {
        padding: 40px 0 0px;
    }
    footer .container{
        max-width: 340px;
    }
    footer .lower-footer h4 {
        font-size: 14px;
        max-width: 250px;
    }
    footer .links-box h5 {
        font-size: 22px;
        line-height: 28px;
    }
    footer .contact-box h5 {
        font-size: 22px;
        line-height: 28px;
    }
    footer .links-box ul li a {
        font-size: 16px;
        line-height: 24px;
    }
    footer .links-box ul li {
        margin-top: 10px;
    }
    footer .contact-box ul li a {
        font-size: 16px;
        line-height: 24px;
    }
    footer .contact-box ul li {
        margin-top: 10px;
    }
    footer .learn-more-box h5 {
        font-size: 22px;
        line-height: 28px;
    }
    footer .learn-more-box p {
        font-size: 14px;
        max-width: 280px;
    }
    footer .logo-box {
        width: 100%;
        
    }
    footer .logo-box img{
        margin: 0 auto;
    }











}
