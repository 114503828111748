#our-offer-section {
    background: #FFFFFF;
    width: 100%;
    height: auto;
    padding: 80px 0 50px;
}

#our-offer-section h4 {
    font-size: 54px;
    font-weight: 700;
    line-height: 80.32px;
    text-align: center;
    color: #0B1623;
    margin: 0 auto;
}

#our-offer-section .inner-row {
    gap: 68px;
    margin-top: 102px;
}

#our-offer-section .inner-row:first-of-type {
    margin-top: 51px;

}

#our-offer-section .inner-row:nth-child(odd) {
    flex-direction: row-reverse;
}

#our-offer-section .inner-row h5 {
    font-size: 44px;
    font-weight: 700;
    line-height: 50.2px;
    text-align: left;
    color: #0B1623;
    margin: 0;
}

#our-offer-section .inner-row p {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    color: #000000;
    margin: 22px 0 0 0;
    max-width: 420px;
}

#our-offer-section .inner-row .line-1 {
    bottom: -128px;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
}

#our-offer-section .inner-row .line-2 {
    bottom: -128px;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;

}

#our-offer-section .inner-row .line-3 {
    bottom: -110px;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;

}

#our-offer-section .inner-row .line-4 {
    bottom: -150px;
    right: 50%;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;

}

#our-offer-section .text {
    font-family: Mulish;
    font-size: 40px;
    font-weight: 700;
    line-height: 50.2px;
    text-align: left;
    color: #0B1623;
    margin: 170px 0 0 160px;
}

@media screen and (max-width: 1400px) {

    #our-offer-section .inner-row .main-img {
        width: 500px;
    }
}

@media screen and (max-width: 1200px) {
    #our-offer-section .inner-row .line-1 {
        display: none;
    }

    #our-offer-section .inner-row .line-2 {
        display: none;
    }

    #our-offer-section .inner-row .line-3 {
        display: none;
    }

    #our-offer-section .inner-row .line-4 {
        display: none;
    }

    #our-offer-section .inner-row {
        flex-direction: column;
    }

    #our-offer-section .inner-row .main-img {
        width: 100%;
    }

    #our-offer-section .inner-row {
        margin-top: 100px;
    }
}

@media screen and (max-width: 992px) {
    #our-offer-section .text {
        text-align: center;
        margin: 80px auto 20px;
    }
}

@media screen and (max-width: 567px) {
    #our-offer-section .inner-row {
        gap: 40px;
        max-width: 340px;
        margin: 50px auto 0;
    }
    #our-offer-section .text {
        margin: 80px auto 0px;
    }

    #our-offer-section .inner-row h5 {
        font-size: 35px;
    }

    #our-offer-section {
        padding: 80px 0;
    }
    #our-offer-section .inner-row p {
        font-size: 20px;
        margin: 10px 0 0 0;
    }
}