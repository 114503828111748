#add-data-section {
    /* margin-top: 150px; */
    margin-top: var(--header-height);

}

.tabs {
    border: 1px solid #000;
    margin: 80px 0;
    background-color: #E2DCC4;
    padding: 20px 0;
}

.tabs .active {
    border-bottom: 4px solid rgba(198, 160, 104, 1);
}

#add-data-section .tab h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40.16px;
    text-align: center;
    color: #000;
    cursor: pointer;
    margin: 0;
    padding-bottom: 10px;

}

.tab-content {
    padding-bottom: 100px;
}

.tab-content .blog-content {
    /* border: 1px solid red; */
}

.tab-content .blog-content h3 {
    font-family: Mulish;
    font-size: 44px;
    font-weight: 700;
    line-height: 80.32px;
    text-align: left;
    color: #0B1623;
    margin: 0;
}

.tab-content .blog-content  {
    border: 1px solid #000;
    padding: 20px;
    max-width: 780px;
    margin: 0 auto;
}

.tab-content .blog-content .add-blog-data .input-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    margin: 20px 0;
}

.tab-content .blog-content .add-blog-data label {
    font-family: Mulish;
    font-size: 26px;
    font-weight: 700;
    line-height: 40.16px;
    text-align: left;
    color: #000000;
    margin: 0;
    width: 200px;

}

.tab-content .blog-content .add-blog-data input {
    width: 500px;
    height: 40px;
    background: #D6BA90;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 0 20px 0 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: rgba(35, 31, 32, 1);
}

.tab-content .blog-content .add-blog-data textarea {
    width: 500px;
    height: 120px;
    background: #D6BA90;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 0 20px 0 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: rgba(35, 31, 32, 1);
}

.tab-content .blog-content .add-blog-data button {
    display: flex;
    align-self: flex-end;
    max-width: 100%;
    width: auto;
    height: 40px;
    text-align: center;
    background: #000;
    color: #fff;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 10px 30px;
    font-size: 20px;
    font-weight: 500;
    line-height: 21px;
    cursor: pointer;
    margin-left: auto;
    margin-top: 20px;

}

.tab-content .blog-content .add-blog-data button:hover {
    opacity: .7;
}

.tab-content .price-content h3 {
    font-family: Mulish;
    font-size: 44px;
    font-weight: 700;
    line-height: 80.32px;
    text-align: left;
    color: #0B1623;
    margin: 0;
}
.tab-content .price-content {
    border: 1px solid #000;
    padding: 20px;
    max-width: 780px;
    margin: 0 auto;
}
.tab-content .price-content table {}

.tab-content .price-content table thead tr th {
    border: 1px solid #000;
    height: 40px;
    text-align: center;
    padding: 10px 20px;

}
.tab-content .price-content table tbody tr td{
    border: none;

}
.tab-content .price-content table tbody tr td:first-child{
    border: 1px solid #000;
    height: 40px;
    padding: 10px 20px;

}
.tab-content .price-content table tbody tr td input{
    width: auto;
    height: 40px;
    background: #D6BA90;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 0 10px 0 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: rgba(35, 31, 32, 1);
}

.tab-content .price-content button {
    display: flex;
    align-self: flex-end;
    max-width: 100%;
    width: auto;
    height: 40px;
    text-align: center;
    background: #000;
    color: #fff;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 10px 30px;
    font-size: 20px;
    font-weight: 500;
    line-height: 21px;
    cursor: pointer;
    margin-left: auto;
    margin-top: 20px;
}
.tab-content .price-content button:hover {
    opacity: .7;
}
@media screen and (max-width: 992px) {
    .tab-content .add-price-data{
        overflow-x: scroll;
    }
}

@media screen and (max-width: 576px) {
    .tab-content .blog-content .add-blog-data .input-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
    .tab-content .blog-content .add-blog-data input {
        width: 100%;
        max-width: 340px;
    }
    .tab-content .blog-content .add-blog-data textarea {
        width: 100%;
        max-width: 340px;
    }
    .tab-content .blog-content .add-blog-data button {
        margin-top: 40px;
    }
    .tab-content .blog-content .add-blog-data label {
        font-size: 20px;
    }
    .tab-content .blog-content h3 {
        font-size: 32px;
        line-height: 40.32px;
    }

    .tab-content .price-content h3 {
        font-size: 32px;
        line-height: 40.32px;
    }



















}
