#booking-section {
    background: #FFFFFF;
    width: 100%;
    height: auto;
    padding: 40px 0 120px;
}

#booking-section h4 {
    font-size: 54px;
    font-weight: 700;
    line-height: 80.32px;
    text-align: center;
    color: #0B1623;
    margin: 0 auto;
}

#booking-section p {
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: #0B1623;
    margin: 16px auto 20px;
    max-width: 720px;
    text-transform: capitalize;
}

#booking-section a {
    width: 435px;
    height: 84px;
    border-radius: 8px;
    background: #D6BA90;
    border: 4px solid #FFFFFF;
    font-size: 40px;
    font-weight: 800;
    line-height: 50.2px;
    text-align: left;
    color: #FFFFFF;
    margin: 80px auto 0;
}

#booking-section a:hover {
    opacity: .7;
}

#booking-section .booking-box {
    margin: 60px 0 0 auto;
    width: 95%;
    padding: 0;
}

#booking-section .booking-box .inner-box {
    cursor: pointer;
    
}

#booking-section .booking-box .inner-box:focus-visible {
    outline: none !important;
}

#booking-section .booking-box .slick-slide>div {
    margin: 0px 10px;
}

#booking-section .booking-box .inner-box img {
    /* margin: 0 auto; */
    width: 376px;
    height: 443px;
    border-radius: 20px;

}

#booking-section .booking-box li {
    width: 450px !important;
}

#booking-section .booking-box .inner-box h5 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40.16px;
    text-align: left;
    color: #0B1623;
    margin: 0px 0 0px;

}

#booking-section .booking-box .inner-box .box {
    margin-top: 20px;
    max-width: 370px;
}

#booking-section .booking-box .inner-box .link {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.14px;
    text-align: left;
    color: #000000;
    margin: 8px 0 0;
    background-color: transparent;
    width: auto;
    height: auto;

}

#booking-section .booking-box .inner-box .link:hover {
    opacity: .7;
}

@media screen and (max-width: 992px) {
    #booking-section .booking-box {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    #booking-section h4 {
        font-size: 38px;
        line-height: 44.32px;
    }

    #booking-section p {
        font-size: 23px;
        line-height: 32px;
        margin: 34px auto 20px;
        max-width: 527px;
    }
}

@media screen and (max-width: 567px) {
    #booking-section h4 {
        font-size: 34px;
        line-height: 44px;
        max-width: 320px;
    }

    #booking-section p {
        font-size: 20px;
        line-height: 28px;
        max-width: 380px;

    }

    #booking-section .booking-box li {
        width: 100% !important;
    }

    #booking-section .booking-box .inner-box img {
        margin: 0 auto;

    }

    #booking-section .booking-box .inner-box h5 {
        font-size: 26px;
        max-width: 360px;
        margin: 20px 0 0;
    }

    #booking-section .booking-box .inner-box .link {
        font-size: 24px;
        max-width: 360px;
        margin: 4px 0 0;
    }

    #booking-section {
        padding: 20px 0 50px;
    }

    #booking-section a {
        max-width: 100%;
        width: 360px;
        height: 74px;
        font-size: 30px;
        line-height: 40px;
        margin: 60px auto 0;
    }
    #booking-section .booking-box .inner-box .box {
        max-width: 370px;
        margin: 20px auto 0;
    }
}