#progress {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    position: fixed;
    bottom: 20px;
    right: -150px;
    display: grid;
    place-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    transition: .6s all ease-in-out;

}

#progress #progress-value {
    height: 34px;
    width: 34px;
    background-color: #fff;
    border-radius: 10px;
    display: grid;
    place-items: center;
    font-weight: 500;
    color: #0B1623;
}

#progress:hover {
    cursor: pointer;

}

#progress.show {
    opacity: 1;
    visibility: visible;
    right: 20px;
    transform: rotate(360deg);
} 