#about-banner-section {
    background-color: #fff;
    padding: 94px 0 206px;
    margin-top: var(--header-height);
    height: auto;
    width: 100%;
}

#about-banner-section .left {}

#about-banner-section .left h2 {
    font-size: 64px;
    font-weight: 700;
    line-height: 80.32px;
    text-align: left;
    color: #0B1623;
    margin: 0;
    max-width: 494px;

}

#about-banner-section .left p {
    font-family: Mulish;
    font-size: 30px;
    font-weight: 400;
    line-height: 40.16px;
    text-align: left;
    color: #0B1623;
    margin: 84px 0 0 0;
    max-width: 396px;

}

#about-banner-section .left a {
    width: 436px;
    height: 82px;
    border-radius: 8px;
    border: 4px solid #231F20;
    font-size: 40px;
    font-weight: 600;
    line-height: 50.2px;
    text-align: center;
    color: #231F20;
    margin: 49px 0 0 0;
}

#about-banner-section .left a:hover {
    opacity: .7;
}

#about-section-1 {
    background: #D6BA90;
    height: auto;

}

#about-section-1 .bg {
    top: 50%;
    transform: translateY(-50%);
}

#about-section-1 p {
    top: 50%;
    transform: translateY(-50%);
    font-family: Mulish;
    font-size: 32px;
    font-weight: 700;
    line-height: 40.16px;
    text-align: left;
    max-width: 336px;
    margin: 0 auto 0 65px;
    text-transform: capitalize;
}

@media screen and (max-width: 1400px) {
    #about-banner-section .right img {
        width: 610px;
    }
}

@media screen and (max-width: 1200px) {
    #about-banner-section .right img {
        width: 540px;
    }

    #about-banner-section .left a {
        width: 380px;
    }

    #about-banner-section .left h2 {
        font-size: 50px;
        max-width: 380px;
    }




}

@media screen and (max-width: 992px) {
    #about-banner-section .right {
        margin-top: 80px;
    }

    #about-banner-section .right img {
        width: 100%;
    }

    #about-banner-section .left p {
        margin: 40px 0 0 0;
    }

    #about-section-1 .bg {
        position: static;
        transform: translateY(0);
        margin-top: 60px;
    }

    #about-section-1 .row {
        gap: 70px;
    }

    #about-section-1 .row:last-of-type {
        flex-direction: column-reverse;
    }

    #about-section-1 {
        padding: 40px 0 80px;
    }
    .w-lg-50{
        margin-right: 0px !important;
    }

}

@media screen and (max-width: 576px) {
    #about-banner-section {
        padding: 50px 0 100px;
    }
    #about-section-1 .row {
        gap: 30px;
    }
    #about-banner-section .left h2 {
        font-size: 40px;
        line-height: 58.32px;
    }

    #about-banner-section .left p {
        font-size: 24px;
        line-height: 34.16px;
        margin: 40px 0 0 0;
    }

    #about-banner-section .left a {
        width: 100%;
        font-size: 30px;
        max-width: 436px;
    }
    #about-section-1 p {
        font-size: 22px;
        line-height: 35.16px;
        max-width: 317px;
    }
}
@media screen and (max-width: 440px) {
    #about-section-1 .bg {
        display: none;
    }
    #about-section-1 p {
        position: static;
        transform: translate(0);
        margin: 0;
    }
    #about-section-1 .row:last-of-type {
        margin-top: 60px;
    }
}
.w-lg-50{
    margin-right: -20px;
}