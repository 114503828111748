.people-cards {
    width: 264px;
    height: 378px;
    padding: 25px 25px 30px 25px;
    border-radius: 20px;
    background: #C6A068;


}

.people-cards h3 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    color: #FFFFFF;
    margin: 30px auto 0;
    max-width: 150px;
}

.people-cards h5 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    color: #FFFFFF;
    margin: 15px auto 0;

}

.people-cards h6 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    color: #FFFFFF;
    margin: 5px auto 0;

}

.people-cards .stars {
    margin-top: 15px;
}
@media screen and (max-width: 1200px) {
    .people-cards {
        width: 215px;
        padding: 15px 15px 20px 15px;
    }
}
@media screen and (max-width: 992px) {
    .people-cards {
        width: 264px;
        padding: 25px 25px 30px 25px;
    }
    
}