#people-section {
    width: 100%;
    height: 818px;
    padding: 120px 0;
    background: url("../../assets/bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#people-section h4 {
    font-size: 64px;
    font-weight: 700;
    line-height: 80.32px;
    text-align: center;
    color: #0B1623;
    margin: 0 auto;
}

#people-section .review-cards {
    margin-top: 120px;
    gap: 48px;
}
@media screen and (max-width: 1400px) {
    #people-section .review-cards {
        margin-top: 120px;
        gap: 20px;
    }
}
@media screen and (max-width: 992px) {
    #people-section {
        height: auto;
        padding: 80px 0 130px;
    }
    #people-section h4 {
        font-size: 40px;
        line-height: 62.32px;
    }
    #people-section .review-cards {
        margin-top: 50px;
        gap: 40px;
    }

}
