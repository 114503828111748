:root {
  --header-height: 100px;
  --white: #ffffff;
}

* {
  box-sizing: border-box;
}

html {
  margin: 0 !important;
  scroll-behavior: smooth !important;
}


body {
  padding: 0;
  margin: 0;
  font-family: "Mulish", sans-serif;
  /* background-color: #f5f5f5; */

}

a {
  text-decoration: none;
}

img,
video {
  max-width: 100%;
  height: auto;
  display: block;
}

li {
  list-style: none;
}

/* Container Class  */

.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}


@media (min-width: 576px) {

  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

/* Container Class  */

/* Flex Class */

.d-lg-flex {
  display: flex;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
}

.place-center {
  justify-content: center;
  align-items: center;
}

.w-lg-50 {
  width: 50%;
}

.h-full {
  height: 100%;
}

.j-center {
  justify-content: center;
}

.j-between {
  justify-content: space-between;
}

.a-center {
  align-items: center;
}

.g-10 {
  gap: 10px;
}

.g-15 {
  gap: 15px;
}

.g-20 {
  gap: 20px;
}
.g-30 {
  gap: 30px;
}
.g-40 {
  gap: 40px;
}
.g-60 {
  gap: 60px;
}
.g-90 {
  gap: 90px;
}
.p-relative{
  position: relative;
}
.p-absolute{
  position: absolute;
}

@media (max-width: 992px) {
  .d-lg-flex {
    flex-direction: column;
  }

  .w-lg-50 {
    width: 100%;
  }
}

/* Flex Class */

.main-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: center;
  color: #122A5A;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .main-title {
    font-size: 45px;
    line-height: 60px;
  }

}

@media (max-width: 992px) {
  .main-title {
    font-size: 40px;
    line-height: 55px;
  }

}

@media (max-width: 768px) {
  .main-title {
    font-size: 35px;
    line-height: 50px;
  }

}

@media (max-width: 576px) {
  .main-title {
    font-size: 30px;
    line-height: 40px;
  }

}