#location-section {
    width: 100%;
    height: 579px;
    background: rgba(198, 160, 104, 1);
}

#location-section h3 {
    font-family: Parisienne;
    font-size: 50px;
    font-weight: 400;
    line-height: 68.12px;
    text-align: center;
    color: #0B1623;
    margin: 0 auto;
}

#location-section p {
    font-size: 24px;
    font-weight: 400;
    line-height: 30.12px;
    text-align: center;
    color: #0B1623;
    margin: 4px auto 0;

}

#location-section p a {
    font-size: 24px;
    font-weight: 400;
    line-height: 30.12px;
    text-align: center;
    color: #0B1623;
    margin: 0 auto;
    text-decoration: underline;
}

#location-section p a:hover {
    opacity: .8;
}

#location-section .box {
    margin-top: 113px;
}

#location-section .box .inner-box {
    width: 166px;
    height: 166px;
    border-radius: 50%;
    background: #FFFFFF;

}
@media screen and (max-width: 567px) {
    #location-section {
        height: auto;
        padding: 70px 0;
    }
    #location-section h3 {
        font-size: 40px;
    }
    #location-section p {
        font-size: 20px;
    }
    #location-section p a {
        font-size: 20px;
    }
    #location-section .box {
        margin-top: 60px;
    }
    #location-section .box .inner-box {
        width: 80px;
        height: 80px;
    }
    #location-section .box .inner-box img{
        transform: scale(.7);
    }







}