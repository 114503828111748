#banner-section {
    margin-top: var(--header-height);
    height: 894px;
    width: 100%;
    background: url("../../assets/NewBg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

#banner-section h1 {
    font-size: 50px;
    font-weight: 800;
    line-height: 70.28px;
    text-align: left;
    color: #FFFFFF;
    max-width: 460px;
    margin: 0;
}

#banner-section h3 {
    font-size: 30px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
    color: #FFFFFF;
    max-width: 480px;
    margin: 32px 0 0 0;

}

#banner-section a {
    width: 436px;
    height: 82px;
    border-radius: 8px ;
    border: 4px solid #FFFFFF;
    font-size: 40px;
    font-weight: 800;
    line-height: 50.2px;
    text-align: left;
    background: transparent;
    color: #FFFFFF;
    margin: 56px 0 0 0;

}

#banner-section a:hover {
    opacity: .7;
}


@media screen and (max-width: 1200px) {
    #banner-section {
        background-position: left;
    }
}
@media screen and (max-width: 567px) {
    #banner-section {
        height: 640px;
    }
    #banner-section h1 {
        font-size: 38px;
        line-height: 50px;
        max-width: 380px;
        margin: 0 auto;
    }
    #banner-section h3 {
        font-size: 24px;
        line-height: 32px;
        max-width: 380px;
        margin: 20px auto 0;

    }
    #banner-section a {
        font-size: 30px;
        max-width: 380px;
        height: 70px;
        width: 100%;
        margin: 50px auto 0;
    }

}
