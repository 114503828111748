#our-services-section {
    width: 100%;
    height: 995px;
    padding: 120px 0;
    background: url("../../assets/service-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#our-services-section h4 {
    font-family: Mulish;
    font-size: 64px;
    font-weight: 700;
    line-height: 80.32px;
    text-align: center;
    color: #0B1623;
    margin: 0 auto;
}

#our-services-section .services-cards {
    margin-top: 120px;
    gap: 48px;
}

#our-services-section .services-cards .card h5 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50.2px;
    text-align: left;
    color: #0B1623;
    margin: 0 auto;
}

#our-services-section a {
    width: 436px;
    height: 82px;
    border-radius: 8px;
    border: 4px solid #FFFFFF;
    font-size: 40px;
    font-weight: 800;
    line-height: 50.2px;
    text-align: left;
    color: #FFFFFF;
    margin: 77px auto 0;
}
#our-services-section a:hover{
    opacity: .7;
}
@media screen and (max-width: 1400px) {
    #our-services-section .services-cards {
        gap: 25px;
    }
}
@media screen and (max-width: 1200px) {
    #our-services-section {
        height: auto;
        padding: 120px 0;
    }
}
@media screen and (max-width: 576px) {
    #our-services-section h4 {
        font-size: 42px;
        line-height: 46.32px;
    }
    #our-services-section {
        padding: 60px 0;
    }

    #our-services-section .services-cards {
        margin-top: 60px;
        gap: 70px;
    }
    #our-services-section .services-cards .card h5 {
        font-size: 30px;
        line-height: 20.2px;
    }
    #our-services-section a {
        width: 100%;
        font-size: 30px;
        max-width: 436px;
    }







}

